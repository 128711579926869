import {KpiScore} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Stack, Tooltip, Typography} from '@mui/material'

type Variant = 'neutral' | 'positive' | 'negative'

type Props = {
  label: string
  value: number
  valueVariant: Variant
  showTooltip?: boolean
  tooltipText?: string
  dataTestId: string
}

export const MyTasksSummaryScore = ({
  label,
  value,
  valueVariant,
  showTooltip = false,
  tooltipText = '',
  dataTestId
}: Props) => {
  const content = (
    <KpiScore
      label={label}
      labelIcon={showTooltip ? <InfoOutlined /> : undefined}
      value={value}
      variant={valueVariant}
      data-test-id={dataTestId}
    />
  )

  return showTooltip ? (
    <Tooltip
      arrow
      placement="right"
      title={<Typography fontWeight="medium">{tooltipText}</Typography>}
    >
      <span>{content}</span>
    </Tooltip>
  ) : (
    content
  )
}
