import {SxProps, Theme} from '@mui/material'

import {CardBoxSize} from '../types'

export const BOX_WIDTHS: Record<CardBoxSize, string> = {
  XS: '158px',
  S: '332px',
  M: '332px',
  L: '680px'
}

export const BOX_HEIGHTS: Record<CardBoxSize, string> = {
  XS: '158px',
  S: '158px',
  M: '332px',
  L: '332px'
}

export const cardBoxSizes: Record<CardBoxSize, SxProps<Theme>> = {
  XS: {
    width: BOX_WIDTHS.XS,
    height: BOX_HEIGHTS.XS
  },
  S: {
    width: BOX_WIDTHS.S,
    height: BOX_HEIGHTS.S
  },
  M: {
    minWidth: BOX_WIDTHS.M,
    height: BOX_HEIGHTS.M
  },
  L: {
    minWidth: BOX_WIDTHS.L,
    height: BOX_HEIGHTS.L
  }
}

export const placeholderWrapperSizes = {
  XS: {
    height: '100px'
  },
  S: {
    height: '100px'
  },
  M: {},
  L: {}
}
