import {CardBox} from '@hconnect/uikit/src/lib2'
import {CalendarTodayOutlined, ShowChart} from '@mui/icons-material'
import {Box, Chip, Typography} from '@mui/material'
import {isEmpty} from 'lodash'
import moment from 'moment'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone, useTranslationPrefix} from '../../shared/hooks'
import {CardLinkWrapper, DataContentWrapper, PlantStatusKpis} from '../components'
import {cardBoxSizes} from '../consts'
import {useDailyPerformanceKpis, useMonthlyAggregatedKpis} from '../hooks/api'
import {mapToPlantStatusMontlyKpiTrends} from '../mappers'
import {
  formatKpiValue,
  getHasDenodoDataForDate,
  getReliabilityEngineerPerformancePagePath
} from '../utils'

type Props = {
  plantId: string
}

const AGGREGATION_MONTHS_COUNT = 6

export const ReliabilityPlantStatusKpisContainer: React.FC<Props> = ({plantId}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()
  const to = moment.utc().tz(timezone).startOf('month')
  const from = to.clone().subtract(AGGREGATION_MONTHS_COUNT, 'months')

  const {
    data: monthlyAggregatedKpis,
    isLoading: areMonthlyKpiTrendsLoading,
    isError: isMontlyKpisError,
    isRefetching: isMonthlyKpisRefetching,
    refetch: refetchMonthlyKpis
  } = useMonthlyAggregatedKpis({plantId, from, to})
  const monthlyKpiTrends = mapToPlantStatusMontlyKpiTrends(monthlyAggregatedKpis)

  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'days').startOf('day')
  const endOfYesterday = startOfYesterday.clone().endOf('day')
  const {
    data: yesterdayKpis,
    isLoading: isYesterdayKpisLoading,
    isError: isYesterdayKpisError,
    isRefetching: isYesterdayKpisRefetching,
    refetch: refetchYesterdayKpis
  } = useDailyPerformanceKpis({plantId, from: startOfYesterday, to: endOfYesterday})

  const lastMonth = moment.tz(timezone).subtract(1, 'month')
  const hasLastMonthDenodoData = getHasDenodoDataForDate(monthlyAggregatedKpis, lastMonth)
  const linkPath = getReliabilityEngineerPerformancePagePath(
    plantId,
    hasLastMonthDenodoData ? 'lastMonth' : 'yesterday'
  )

  const kpiItems = useMemo(
    () => [
      {
        key: 'clinker-production',
        label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
        value: formatKpiValue(yesterdayKpis?.clinkerProduction?.value, language),
        unit: yesterdayKpis?.clinkerProduction?.unit,
        monthlyTrendDataUnit: 't',
        kpiBreachSeverity: yesterdayKpis?.clinkerProduction?.kpiBreachSeverity,
        monthlyTrendData: monthlyKpiTrends?.clinkerProduction
      },
      {
        key: 'kiln-operating-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnOperatingCoefficient`),
        monthlyTrendDataUnit: '%',
        monthlyTrendData: monthlyKpiTrends?.kilnOperatingCoefficient
      },
      {
        key: 'alternative-fuel-rate',
        label: t(`${hrocPrefix}.plantStatus.label.alternativeFuelRate`),
        value: formatKpiValue(yesterdayKpis?.alternativeFuelRate?.value, language),
        unit: yesterdayKpis?.alternativeFuelRate?.unit,
        monthlyTrendDataUnit: '%',
        kpiBreachSeverity: yesterdayKpis?.alternativeFuelRate?.kpiBreachSeverity,
        monthlyTrendData: monthlyKpiTrends?.alternativeFuelRate
      },
      {
        key: 'cement-production',
        label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
        value: formatKpiValue(yesterdayKpis?.cementProduction?.value, language),
        unit: yesterdayKpis?.cementProduction?.unit,
        monthlyTrendDataUnit: 't',
        kpiBreachSeverity: yesterdayKpis?.cementProduction?.kpiBreachSeverity,
        monthlyTrendData: monthlyKpiTrends?.cementProduction
      },
      {
        key: 'mean-time-between-failure',
        label: t(`${hrocPrefix}.plantStatus.label.meanTimeBetweenFailure`),
        monthlyTrendDataUnit: 'h',
        monthlyTrendData: monthlyKpiTrends?.meanTimeBetweenFailure
      },
      {
        key: 'kiln-reliability-coefficient',
        label: t(`${hrocPrefix}.plantStatus.label.kilnReliabilityCoefficient`),
        monthlyTrendDataUnit: '%',
        monthlyTrendData: monthlyKpiTrends?.kilnReliabilityCoefficient
      }
    ],
    [yesterdayKpis, monthlyKpiTrends, t, hrocPrefix, language]
  )

  return (
    <CardLinkWrapper
      href={linkPath}
      data-test-id="plant-status-kpis-card-link-to-performance-plant-status"
    >
      <CardBox sx={cardBoxSizes['L']} data-test-id="plant-status-kpis-card">
        <Box display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h3" mb={2}>
              {t(`${hrocPrefix}.plantStatus.cardTitle`)}
            </Typography>
          </Box>
          <Chip
            icon={<CalendarTodayOutlined />}
            label={t(`${hrocPrefix}.label.yesterday`)}
            size="small"
            sx={{mx: 1}}
          />
          <Chip
            icon={<ShowChart />}
            label={t(`${hrocPrefix}.label.monthsCount`, {count: AGGREGATION_MONTHS_COUNT})}
            size="small"
            sx={{mx: 1}}
          />
        </Box>
        <DataContentWrapper
          isLoading={areMonthlyKpiTrendsLoading && isYesterdayKpisLoading}
          isError={isMontlyKpisError || isYesterdayKpisError}
          isRefetching={isMonthlyKpisRefetching || isYesterdayKpisRefetching}
          isNoData={
            !areMonthlyKpiTrendsLoading &&
            isEmpty(monthlyAggregatedKpis) &&
            !isYesterdayKpisLoading &&
            isEmpty(yesterdayKpis)
          }
          retryFunction={() => {
            if (isMontlyKpisError) {
              void refetchMonthlyKpis()
            }
            if (isYesterdayKpisError) {
              void refetchYesterdayKpis()
            }
          }}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusKpis
            kpiItems={kpiItems}
            areMonthlyKpiTrendsLoading={areMonthlyKpiTrendsLoading}
            gridItemXs={4}
          />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
