import {CardBox} from '@hconnect/uikit/src/lib2'
import moment from 'moment'
import {useTranslation} from 'react-i18next'

import {usePlantTimezone, useTranslationPrefix} from '../../shared/hooks'
import {
  CardLinkWrapper,
  DataContentWrapper,
  PlantStatusCardHeader,
  PlantStatusDailyKpis
} from '../components'
import {useDailyPerformanceKpis} from '../hooks/api'
import {formatKpiValue, getProcessEngineerPerformancePagePath} from '../utils'

type Props = {
  plantId: string
}

export const ProcessPlantStatusDailyKpisContainer: React.FC<Props> = ({plantId}) => {
  const {t, i18n} = useTranslation()
  const language = i18n.language
  const {hrocPrefix} = useTranslationPrefix()
  const timezone = usePlantTimezone()

  const startOfYesterday = moment.utc().tz(timezone).subtract(1, 'days').startOf('day')
  const endOfYesterday = startOfYesterday.clone().endOf('day')
  const {
    data: yesterdayKpis,
    isLoading: isYesterdayKpisLoading,
    isError: isYesterdayKpisError,
    isRefetching: isYesterdayKpisRefetching,
    refetch: refetchYesterdayKpis
  } = useDailyPerformanceKpis({plantId, from: startOfYesterday, to: endOfYesterday})

  const linkPath = getProcessEngineerPerformancePagePath(plantId, 'yesterday')

  const kpiItems = [
    {
      key: 'clinker-production',
      label: t(`${hrocPrefix}.plantStatus.label.clinkerProduction`),
      value: formatKpiValue(yesterdayKpis?.clinkerProduction?.value, language),
      unit: yesterdayKpis?.clinkerProduction?.unit,
      kpiBreachSeverity: yesterdayKpis?.clinkerProduction?.kpiBreachSeverity
    },
    {
      key: 'cement-production',
      label: t(`${hrocPrefix}.plantStatus.label.cementProduction`),
      value: formatKpiValue(yesterdayKpis?.cementProduction?.value, language),
      unit: yesterdayKpis?.cementProduction?.unit,
      kpiBreachSeverity: yesterdayKpis?.cementProduction?.kpiBreachSeverity
    },
    {
      key: 'kiln-heat-consumption',
      label: t(`${hrocPrefix}.plantStatus.label.kilnHeatConsumption`)
    },
    {
      key: 'raw-mill-sp-power-consumption',
      label: t(`${hrocPrefix}.plantStatus.label.rawMillsSpPowerConsumption`)
    },
    {
      key: 'kiln-sp-power-consumption',
      label: t(`${hrocPrefix}.plantStatus.label.kilnPowerConsumption`)
    },
    {
      key: 'cement-mills-sp-power-consumption',
      label: t(`${hrocPrefix}.plantStatus.label.cementMillsPowerConsumption`)
    },
    {
      key: 'raw-mills-production-rate',
      label: t(`${hrocPrefix}.plantStatus.label.rawMillsProductionRate`)
    },
    {
      key: 'clinker-production-rate',
      label: t(`${hrocPrefix}.plantStatus.label.clinkerProductionRate`)
    },
    {
      key: 'cement-mills-production-rate',
      label: t(`${hrocPrefix}.plantStatus.label.cementMillsProductionRate`)
    }
  ]

  return (
    <CardLinkWrapper
      href={linkPath}
      data-test-id="plant-status-kpis-card-link-to-performance-plant-status"
    >
      <CardBox data-test-id="plant-status-kpis-card">
        <PlantStatusCardHeader />
        <DataContentWrapper
          isLoading={isYesterdayKpisLoading}
          isError={isYesterdayKpisError}
          isRefetching={isYesterdayKpisRefetching}
          data={yesterdayKpis}
          retryFunction={() => void refetchYesterdayKpis()}
          progressSx={{color: 'primary.main'}}
        >
          <PlantStatusDailyKpis kpiItems={kpiItems} />
        </DataContentWrapper>
      </CardBox>
    </CardLinkWrapper>
  )
}
