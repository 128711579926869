import {FlexPage} from '@hconnect/common/components/FlexPage'
import {Box, Stack, useMediaQuery, useTheme} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {BOX_HEIGHTS, BOX_WIDTHS, cardBoxSizes, placeholderWrapperSizes} from '../consts'
import {
  KilnMonitoringContainer,
  MyTasksContainer,
  OperationHoursContainer,
  ReliabilityPlantStatusDailyKpisContainer,
  ReliabilityPlantStatusKpisContainer,
  RcfaContainer,
  WorkOrdersSummaryContainer
} from '../containers'
import {getReliabilityEngineerStoppageAnalysisPagePath} from '../utils'

type PathParameter = {
  plantId?: string
}

export const ReliabilityEngineerDashboard = () => {
  const {plantId} = useParams<PathParameter>()
  if (!plantId) throw new Error('plantId is missing')

  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const navigate = useNavigate()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const isTablet = !useMediaQuery(theme.breakpoints.down('sm'))

  const redirectToStoppageAnalysis = (equipmentId: string) => {
    navigate(getReliabilityEngineerStoppageAnalysisPagePath(plantId, equipmentId))
  }

  return (
    <FlexPage title={t(`${hrocPrefix}.reliabilityEngineerDashboard.pageTitle`)}>
      {isMobile ? (
        <MobileLayout
          isTablet={isTablet}
          kilnMonitoring={
            <KilnMonitoringContainer
              plantId={plantId}
              cardBoxSx={{width: '100%'}}
              placeholderWrapperSx={{width: '100%'}}
            />
          }
          workOrdersSummary={
            <WorkOrdersSummaryContainer plantId={plantId} cardBoxSx={{height: BOX_HEIGHTS['M']}} />
          }
          plantStatusKpis={<ReliabilityPlantStatusDailyKpisContainer plantId={plantId} />}
          operationHours={
            <OperationHoursContainer
              plantId={plantId}
              onEquipmentClick={redirectToStoppageAnalysis}
              cardBoxSx={{width: '100%'}}
            />
          }
          rcfa={
            <RcfaContainer
              plantId={plantId}
              cardBoxSx={{height: BOX_HEIGHTS['S']}}
              placeholderWrapperSx={{...placeholderWrapperSizes['S'], width: '100%'}}
            />
          }
          myTasks={
            <MyTasksContainer
              cardBoxSx={{height: BOX_HEIGHTS['S']}}
              placeholderWrapperSx={{...placeholderWrapperSizes['S'], width: '100%'}}
            />
          }
        />
      ) : (
        <DesktopLayout
          kilnMonitoring={
            <KilnMonitoringContainer
              plantId={plantId}
              cardBoxSx={{
                height: {sm: '100%', lg: BOX_WIDTHS['M']},
                width: {sm: '100%', lg: BOX_WIDTHS['M']}
              }}
            />
          }
          workOrdersSummary={
            <WorkOrdersSummaryContainer plantId={plantId} cardBoxSx={cardBoxSizes['M']} />
          }
          plantStatusKpis={<ReliabilityPlantStatusKpisContainer plantId={plantId} />}
          operationHours={
            <OperationHoursContainer
              plantId={plantId}
              onEquipmentClick={redirectToStoppageAnalysis}
              cardBoxSx={cardBoxSizes['M']}
            />
          }
          rcfa={
            <RcfaContainer
              plantId={plantId}
              cardBoxSx={{...cardBoxSizes['S'], width: {sm: BOX_WIDTHS['S'], md: '100%'}}}
              placeholderWrapperSx={placeholderWrapperSizes['S']}
            />
          }
          myTasks={
            <MyTasksContainer
              cardBoxSx={{...cardBoxSizes['S'], width: {sm: BOX_WIDTHS['S'], md: '100%'}}}
              placeholderWrapperSx={placeholderWrapperSizes['S']}
            />
          }
        />
      )}
    </FlexPage>
  )
}

type LayoutProps = {
  kilnMonitoring: React.ReactNode
  workOrdersSummary: React.ReactNode
  plantStatusKpis: React.ReactNode
  operationHours: React.ReactNode
  rcfa: React.ReactNode
  myTasks?: React.ReactNode
}

const DesktopLayout: React.FC<LayoutProps> = ({
  kilnMonitoring,
  workOrdersSummary,
  plantStatusKpis,
  operationHours,
  rcfa,
  myTasks
}) => {
  const theme = useTheme()
  const isLarge = !useMediaQuery(theme.breakpoints.down('lg'))

  return isLarge ? (
    <Stack direction="row" spacing={2} flexGrow={1} useFlexGap flexWrap="wrap">
      <Stack spacing={2} flex={1} useFlexGap>
        {plantStatusKpis}
        <Stack direction="row" spacing={2}>
          <Box flex={2}>{operationHours}</Box>
          <Box flex={1}>{workOrdersSummary}</Box>
        </Stack>
      </Stack>
      <Stack spacing={2} useFlexGap>
        {kilnMonitoring}
        <Stack spacing={2}>
          {rcfa}
          {myTasks}
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack direction="row" spacing={2} flexGrow={1} useFlexGap flexWrap="wrap">
      <Stack spacing={2} flex={1} useFlexGap>
        {plantStatusKpis}
        <Stack direction="column" spacing={2} flex={1} useFlexGap>
          {kilnMonitoring}
          {operationHours}
        </Stack>
      </Stack>
      <Stack spacing={2} flex={1} direction="row" useFlexGap>
        <Box sx={{display: 'flex', flex: '1 0 0'}}>{workOrdersSummary}</Box>
        <Stack spacing={2} flex={1} useFlexGap flexWrap="wrap">
          <Box sx={{display: 'flex', flex: '1 0 0'}}>{rcfa}</Box>
          <Box sx={{display: 'flex', flex: '1 0 0'}}>{myTasks}</Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

type MobileProps = LayoutProps & {
  isTablet: boolean
}

const MobileLayout: React.FC<MobileProps> = ({
  isTablet,
  kilnMonitoring,
  workOrdersSummary,
  plantStatusKpis,
  operationHours,
  rcfa,
  myTasks
}) => {
  return (
    <Stack spacing={1} useFlexGap flex={1} direction="row" flexWrap="wrap">
      {plantStatusKpis}
      {kilnMonitoring}
      {operationHours}
      <Stack spacing={1} flex={1} direction={isTablet ? 'row' : 'column'} useFlexGap>
        <Box sx={{display: 'flex', flex: '1 0 0'}}>{workOrdersSummary}</Box>
        <Stack spacing={isTablet ? 2 : 1} flex={1} useFlexGap flexWrap="wrap">
          <Box sx={{display: 'flex', flex: '1 0 0'}}>{rcfa}</Box>
          <Box sx={{display: 'flex', flex: '1 0 0'}}>{myTasks}</Box>
        </Stack>
      </Stack>
    </Stack>
  )
}
