import {getTranslationKey} from '@hconnect/common/utils'
import {AxiosError} from 'axios'
import {Moment} from 'moment'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'
import {useQuery, UseQueryOptions} from 'react-query'

import {ApiPath} from '../../../shared/consts'
import {useAxios, useTranslationPrefix} from '../../../shared/hooks'
import {type MonthlyAggregatedPlantProductionKpisDto} from '../../types'

const QueryKey = 'monthly-aggregated-kpis'

type MonthlyAggregatedKpisParams = {
  plantId: string
  from: Moment
  to: Moment
}

export const useMonthlyAggregatedKpis = <TSelect = MonthlyAggregatedPlantProductionKpisDto[]>(
  {plantId, ...searchParams}: MonthlyAggregatedKpisParams,
  options?: UseQueryOptions<MonthlyAggregatedPlantProductionKpisDto[], AxiosError, TSelect>
) => {
  const axiosInstance = useAxios()
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {from, to} = searchParams
  const params = {
    fromYear: from.year(),
    fromMonth: from.month() + 1, // Adjusting because Moment.js months are 0-indexed
    toYear: to.year(),
    toMonth: to.month() + 1
  }

  return useQuery<MonthlyAggregatedPlantProductionKpisDto[], AxiosError, TSelect>({
    queryKey: [QueryKey, plantId, from.toISOString(), to.toISOString()],
    queryFn: async () => {
      const path = `${ApiPath.KPI}/actual-production-values/${plantId}/kpis/monthly-aggregated`
      const response = await axiosInstance.get<MonthlyAggregatedPlantProductionKpisDto[]>(path, {
        params
      })

      return response.data
    },
    onError: () => {
      enqueueSnackbar(
        t(getTranslationKey('plantStatus.message.failedToFetchMonthlyKPIs', hrocPrefix)),
        {
          variant: 'error'
        }
      )
    },
    ...options
  })
}
